$primary: #f24f00;

@font-face {
  font-family: 'FuturaDemi';
  src: url('./assets/fonts/futura/FuturaCEZ-Demi.ttf') format('truetype');

}
@font-face {
  font-family: 'FuturaMedium';
  src: url('assets/fonts/futura/FuturaCEZ-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Nimbus';
  src: url('assets/fonts/nimbus/NimbusCEZ.ttf') format('truetype');
}

@font-face {
  font-family: RoobertCEZ;
  src: url('assets/fonts/cez/RoobertCEZ-Bold.woff2?v=3') format("woff2"),url("/assets/fonts/cez/RoobertCEZ-Bold.woff?v=3") format("woff");
  font-weight: 600;
  font-style: normal
}

html, body, #root {
  width: 100%;
  height: 100%;
  font-size: 16px;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3 {
  margin: 0;
  font-family: RoobertCEZ, sans-serif;
  color: #063;
  text-transform: uppercase;
}

h2 {
  font-size: 1.3em;
}

p {
  margin-top: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.content {
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  background: white;
  margin: 0.5rem 2rem;
}

.content, .input-wrapper, .sidebar--wrapper {
  box-shadow: 0 12px 17px 2px rgba(0,0,0,0.07), 0 5px 22px 4px rgba(0,0,0,0.06), 0 7px 8px -4px rgba(0,0,0,0.1);
}

.admin {
  padding: 0 1.5rem 0.5rem 1.5rem;

  @media only screen and (max-device-width: 767px) {
    flex-wrap: wrap;
  }

  .input-wrapper {
    background: white;

    @media only screen and (min-device-width: 768px) {
      width: 40%;
    }

    .MuiSlider-root {
      margin-top: 2rem;
    }
  }

  .sidebar--wrapper {
    @media only screen and (min-device-width: 768px) {
      width: 60%;
    }
  }

  iframe {
    border: 1px solid $primary;
  }

}

.sidebar {
  &--wrapper {
    background: white;
  }
}

#root {
  *[class^="MuiTypography"] {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
  }

  .MuiFormControl-root, .MuiTypography-root, .MuiFormControlLabel-root {
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
  }

  .MuiInputLabel-formControl {
    font-size: 1.3rem;
    width: 130%;
  }
}

.data-inputs {
  padding: 1rem;
  margin-bottom: 1rem;

  &--wrapper {
    max-width: 400px;
  }
}

div[class^="p-"] {
  box-sizing: border-box;
}

pre {
  word-break: break-all;
  white-space: normal;
  border: 1px solid black;
  width: auto;

  code {
    white-space: inherit !important;
  }
}

.header {
  display: flex;
  color: white;
  align-items: center;
  margin-top: 0.5rem;

  h1 {
    font-size: 1.5rem;
    margin-left: 1rem;
    display: inline;
    padding-top: 4px;

    &.logo-title {
      color: #f24f00;
      letter-spacing: 1px;
      font-weight: 600;
      padding: 0;
      margin-left: 0.6rem;
    }
  }

  .logo {
    width: 50px;
    height: 50px;
    display: inline-flex;
    margin-left: 2rem;
  }

}

button {
  max-width: 300px;
}

.footer {
  color: #b8b3ad;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;

  .legalese {
    font-size: xx-small;
    text-align: justify;
  }
}

.bvLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #63666a;
  margin-bottom: 1rem;

  .logo {
    height: 3rem;
  }
}

// utils classes - write css above !!

.d {
  &-flex {
    display: flex;

    &--row {
      display: flex;
      flex-direction: row;
    }

    &--column {
      display: flex;
      flex-direction: column;
    }
  }
}
.align {
  &-items {
    &-center {
      align-items: center;
    }
  }
}
.w {
  &-10 {
    width: 10%;
  }

  &-20 {
    width: 20%;
  }

  &-30 {
    width: 30%;
  }

  &-40 {
    width: 40%;
  }

  &-50 {
    width: 50%;
  }

  &-60 {
    width: 60%;
  }

  &-60 {
    width: 60%;
  }

  &-70 {
    width: 70%;
  }

  &-80 {
    width: 80%;
  }

  &-90 {
    width: 90%;
  }

  &-100 {
    width: 100%;
  }
}

.p {
  &-1 {
    padding: 0.5rem;
  }

  &-2 {
    padding: 1rem;
  }

  &-3 {
    padding: 1.5rem;
  }

  &-4 {
    padding: 2rem;
  }

  &-5 {
    padding: 2.5rem;
  }
}

.m {
  &-1 {
    margin: 0.5rem;
  }

  &-2 {
    margin: 1rem;
  }

  &-3 {
    margin: 1.5rem;
  }

  &-4 {
    margin: 2rem;
  }

  &-5 {
    margin: 2.5rem;
  }

  &b {
    &-0 {
      margin-bottom: 0;
    }

    &-1 {
      margin-bottom: 0.5rem;
    }

    &-2 {
      margin-bottom: 1rem;
    }

    &-3 {
      margin-bottom: 1.5rem;
    }

    &-4 {
      margin-bottom: 2rem;
    }

    &-5 {
      margin-bottom: 2.5rem;
    }
  }
}

.col {
  &-1 {
    width: 10%;
  }

  &-2 {
    width: 20%;
  }

  &-3 {
    width: 30%;
  }

  &-4 {
    width: 40%;
  }

  &-5 {
    width: 50%;
  }

  &-6 {
    width: 60%;
  }

  &-6 {
    width: 60%;
  }

  &-7 {
    width: 70%;
  }

  &-8 {
    width: 80%;
  }

  &-9 {
    width: 90%;
  }

  &-10 {
    width: 100%;
  }
}



